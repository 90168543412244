import React from 'react'
import './StoreVouchers.css'
import Title from '../../../components/section_title/Title'
import { Link } from 'react-router-dom'

const StoreVouchers = () => {
  return (
    <div className='store_voucher_page'>
        <div className='store_hero'>
           <div className='store_hero_left'>
               <img src='https://static2.hotukdeals.com/images/fit-in/256x/images/b/bookingcom2022logo.png' alt='store logo' />
           </div>
           <div className='store_hero_right'>
               <h4>Valid Booking.com promo codes for August 2024</h4>
               <p>Find our handpicked UK Booking.com discount codes, all tested by our experts</p>
           </div>
        </div>
        <div className='store_voucher_detail'>
            <div className='voucher_detail_left'>
                <h4>Summer at Booking.com</h4>
                <p>Are you ready for big savings this summer at Booking.com? We certainly are! Booking.com have previously offered customers up to 30% off in their sales, as well as cash rewards when you book through their website using our discount codes.</p>
                <p>Just think of how much you could save on your next trip with these Booking.com promo codes. Perhaps you're thinking of a city break in Liverpool or Edinburgh. A spot of sun in the Algarve, Miami, or Dubai. Or how about a scenic escape to mountains and lakes in stunning destinations such as Slovenia, Canada and Lake Garda?</p>
                <p>Expect an easy and intuitive booking process, backed by user reviews to help you make informed decisions. With a vast selection of accommodations worldwide, Booking.com ensures that your stay is tailored to your desires, creating lasting memories.</p>
            </div>
            <div className='voucher_detail_right'>
                <div className='store_voucher_card'>
                    <div className='v_card_wrapper'>
                        <div className='percentage'>15% Off</div>
                        <div className='deal_center'>
                            <div className='deal_type'>
                                <p className='type1'>%Deal</p>
                                <p className='type2'>Editor's Pick</p>
                            </div>
                            <div className='type_content'>
                                <p>Save up to 15% on Booking.com getaway deals</p>
                            </div>
                        </div>
                        <div className='see_deal'>
                            <Link className='see_deal_btn'>See Deal</Link>
                        </div>
                    </div>
                    <div className='v_card_wrapper'>
                        <div className='percentage'>15% Off</div>
                        <div className='deal_center'>
                            <div className='deal_type'>
                                <p className='type1'>%Deal</p>
                                <p className='type2'>Editor's Pick</p>
                            </div>
                            <div className='type_content'>
                                <p>Save up to 15% on Booking.com getaway deals</p>
                            </div>
                        </div>
                        <div className='see_deal'>
                            <Link className='see_deal_btn'>See Deal</Link>
                        </div>
                    </div>
                    <div className='v_card_wrapper'>
                        <div className='percentage'>15% Off</div>
                        <div className='deal_center'>
                            <div className='deal_type'>
                                <p className='type1'>%Deal</p>
                                <p className='type2'>Editor's Pick</p>
                            </div>
                            <div className='type_content'>
                                <p>Save up to 15% on Booking.com getaway deals</p>
                            </div>
                        </div>
                        <div className='see_deal'>
                            <Link className='see_deal_btn'>See Deal</Link>
                        </div>
                    </div>
                    <div className='v_card_wrapper'>
                        <div className='percentage'>15% Off</div>
                        <div className='deal_center'>
                            <div className='deal_type'>
                                <p className='type1'>%Deal</p>
                                <p className='type2'>Editor's Pick</p>
                            </div>
                            <div className='type_content'>
                                <p>Save up to 15% on Booking.com getaway deals</p>
                            </div>
                        </div>
                        <div className='see_deal'>
                            <Link className='see_deal_btn'>See Deal</Link>
                        </div>
                    </div>
                    <div className='v_card_wrapper'>
                        <div className='percentage'>15% Off</div>
                        <div className='deal_center'>
                            <div className='deal_type'>
                                <p className='type1'>%Deal</p>
                                <p className='type2'>Editor's Pick</p>
                            </div>
                            <div className='type_content'>
                                <p>Save up to 15% on Booking.com getaway deals</p>
                            </div>
                        </div>
                        <div className='see_deal'>
                            <Link className='see_deal_btn'>See Deal</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StoreVouchers
