import logo from './logo.svg';
import 'swiper/css';
import 'swiper/css/navigation';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/shared/navbar/Navbar';
import Footer from './components/footer/Footer';
import Vouchers from './pages/vouchers/Vouchers';
import StoreVouchers from './pages/vouchers/store_vouchers/StoreVouchers';
import Product from './pages/product/Product';
import Cart from './pages/cart/Cart';
import Checkout from './pages/checkout/Checkout';
import Shop from './pages/shop/Shop';
import AboutPage from './pages/about/AboutPage';
import Wishlist from './pages/wishlist/Wishlist';
import Index from './pages/index/Index';
import Account from './pages/account/Account';
import Order from './components/order/order.view';
import HomePage from './pages/home/Home';
import './App.css';
import Contact from './pages/contact/Contact';
import Categories from './pages/categories/Categories';
import PrivacyPolicy from './pages/information/privacy-policy/PrivacyPolicy';
import Terms from './pages/information/terms-conditions/Terms';

function App() {
  return (
    <div className="App">
      <div className="app">      
      <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/categories/:id?/:categoryName?" element={<Categories />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/:productName" element={<Product />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/wishlist" element={<Wishlist />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/vouchers" element={<Vouchers />} />
            <Route path="/vouchers/store" element={<StoreVouchers />} />
            <Route path="/account" element={<Account />} />
            <Route path="/order" element={<Order />} />
          </Routes>
          <Footer />
    </Router>
      </div>
    </div>
  );
}

export default App;



    // {
    //     "id": 3,
    //     "image": "/assets/confidence.png",
    //     "title": "SHOP WITH CONFIDENCE",
    //     "disp": "If goods have problem"
    // },