import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import './ProductCrousel.css'


const ProductCrousel = ({product}) => {

    const groupProducts = (products, chunkSize) => {
        const grouped = [];
        for (let i = 0; i < products.length; i += chunkSize) {
            grouped.push(products.slice(i, i + chunkSize));
        }
        return grouped;
    };

    const productGroups = groupProducts(product, 2);

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <FontAwesomeIcon
                    key={i}
                    icon={faStar}
                    color={i <= rating ? 'orange' : 'lightgray'}
                />
            );
        }
        return stars;
    };

    return (
        <>
            <section className="product_slider">
                <div className="cards_slider">
                    <div className="swiper_outer_div">
                        <div className="swiper_body">
                            <Swiper
                                slidesPerView={1}
                                loop={true}
                                centeredSlides={false}
                                spaceBetween={50}
                                // grabCursor={true}
                                navigation={true}
                                breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 20,
                                    },
                                    512: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                    },
                                }}
                                modules={[Navigation]}
                                speed={1000}
                                className="my_swiper"
                            >

                                {productGroups.map((group, index) => (
                                    <SwiperSlide key={index}>    
                                        <div className='product_col'>
                                            {group.map((product) => (
                                                <div key={product.id} className='product_card'>
                                                    <div className='prod_img'>
                                                        <img src={product.image} />
                                                    </div>
                                                    <div className='prod_content'>
                                                        <Link className='prod_category'>{product.category}</Link>
                                                        <Link className='prod_title'>{product.name}</Link>
                                                        <div className='prod_rating'>{renderStars(product.rating)}</div>
                                                        <h3>{product.price}</h3>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default ProductCrousel