import { faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { calculateSubtotal, calculateTax, getPrice } from "../../utils/CartUtils";
import { useState } from "react";


export const SummaryView = ({page, products}) => {
    const [tax, setTax] = useState(0)

    const subtotal = calculateSubtotal(products);
    const taxAmount = calculateTax(subtotal, tax);
  

    return (
    <div className="cart_summary">
        <h2 className='summary_title'>{page} Summary</h2>
        {page == 'Order' && <div className="order_items">
            {products && products.map((item, index) => (
            <div key={index} className="order_item">
                <img src={'https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663'} alt={item.product.productitem_NAME} width={50} height={50} className="product_image" />
                <div className="item_info">
                <p className="item_name">{item.product.productitem_NAME} x {item.quantity}</p>
                </div>
                <span className="item_price">PKR. {getPrice(item)}</span>
            </div>
            ))}
        </div>}
        <div className='subtotal_container'>
            <div className="subtotal">
                <span className='key'>Subtotal</span>
                <span className='value'>PKR. {subtotal}</span>
            </div>
            <div className="subtotal">
                <span className='key'>Tax (Estimated for Pakistan)</span>
                <span className='value'>{taxAmount}%</span>
            </div> 
            <div className="subtotal">
                <span className='key'>Shipping (3-5 Business Days) </span>
                <span className='value'>Free</span>
            </div>
        </div>
        <div className="total">
        <span>Total</span>
        <span>PKR. {subtotal}</span>
        </div>
        {
        page == 'Order' ?
        <>
            <Link to={'/checkout'} className="checkout_btn">
                <FontAwesomeIcon className='icon' icon={faTruck} />
                Place Order
            </Link>
        </> :
        <>
            <Link to={'/checkout'} className="checkout_btn">
                Proceed to checkout
            </Link>
        </>
        } 
    </div>
    )
  };

export const OrderSummaryView = () => {
    <div className="cart_summary">
        <div className='subtotal_container'>
        <div className="subtotal">
            <span className='key'>Subtotal</span>
            <span className='value'>PKR.{3000}</span>
            {/* <span className='value'>Rs.{calculateSubtotal()}</span> */}
        </div>
        <div className="subtotal">
            <span className='key'>Tax (Estimated for Pakistan)</span>
            <span className='value'>PKR.{3000}</span>
        </div> 
        <div className="subtotal">
            <span className='key'>Shipping (3-5 Business Days) </span>
            <span className='value'>Free</span>
        </div>
        </div>
        <div className="total">
        <span>Total</span>
        <span>PKR.{3000}</span>
        </div>
    </div>
  };