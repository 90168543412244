import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faList, faTh, faThLarge, faUserDoctor } from '@fortawesome/free-solid-svg-icons';

import './Shop.css';
import ProductCard from '../../components/productCard/ProductCard';
import Product from '../../components/product/product/product.view';
import ProductCategory from '../../components/product/productcategory/productcategory.view';
import { get } from '../../components/product/productcategory/productcategory.service';
import { getAll as getProducts } from '../../components/product/productitem/productitem.service'
import Hero from '../../components/hero/Hero';

const Shop = () => {
  const [view, setView] = useState('grid');
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [categoriesData, setCategoriesData] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const result = await get();
        setCategoriesData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchProducts = async () => {
      try {
        const result = await getProducts();
        setProducts(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchProducts();
  }, []);

  return (
    <div className="shop_container">
      <Hero title={''} />
      <div className='shop_wrapper'>
        <div className="filter_section">
            <div className="filter_category">
            <div className='departments'>
                <div className='heading'>
                    <span></span>
                    <h4>CATEGORY</h4>
                </div>
            </div>
            <ProductCategory view={'filter'} categoriesData={categoriesData}/>
            </div>
            <div className="filter_price">
            <div className='departments'>
                <div className='heading'>
                    <span></span>
                    <h4>PRICE</h4>
                </div>
            </div>
            <input type="range" min="0" max="1000" step="10" />
            <div className="price_range">
                <span>0</span> - <span>1000</span>
            </div>
            </div>
            <div className="filter_brand">
            <div className='departments'>
                <div className='heading'>
                    <span></span>
                    <h4>Brand</h4>
                </div>
            </div>
            <ul>
                {
                  products?.map((product,index)=> {
                    const parsedProductDetail = product.product_DETAIL ? JSON.parse(product.product_DETAIL) : {};
                    return (
                      <li key={index}>
                        <input type="checkbox" id={product.productitem_ID} /><label htmlFor="brand1">{parsedProductDetail.product_NAME}</label>
                      </li>
                    )
                  })
                }
            </ul>
            </div>
            <div className="filter_color">
            {/* <div className='departments'>
                <div className='heading'>
                    <span></span>
                    <h4>COLOR</h4>
                </div>
            </div>
            <ul>
                <li><input type="checkbox" id="color1" /><label htmlFor="color1">Color 1</label></li>
                <li><input type="checkbox" id="color2" /><label htmlFor="color2">Color 2</label></li>
            </ul> */}
            </div>
        </div>

        <div className="products_section">
            <div className="products_header">
            <div className="sort_options">
                <div className='sort_by'>
                    <h4>SortBy:</h4>
                    <select>
                        <option value="low-to-high">Low to High</option>
                        <option value="high-to-low">High to Low</option>
                    </select>
                </div>

                <div className='sort_by'>
                    <h4>Show:</h4>
                    <select onChange={(e) => setItemsPerPage(Number(e.target.value))}>
                        <option value="5">5 Items</option>
                        <option value="10">10 Items</option>
                        <option value="20">20 Items</option>
                        <option value="50">50 Items</option>
                        <option value="100">100 Items</option>
                    </select>
                </div>
            </div>
            <div className="view_toggle">
                <p>View</p>
                <div className='toggle_btns'>
                  <button onClick={() => setView('grid')} className={view === 'grid' ? 'active' : ''}><FontAwesomeIcon icon={faList} /></button>
                  <button onClick={() => setView('list')} className={view === 'list' ? 'active' : ''}><FontAwesomeIcon icon={faTh} /></button>
                </div>
            </div>
            </div>
            <Product items={itemsPerPage} view={view} />
        </div>
      </div>
    </div>
  );
};

export default Shop;
