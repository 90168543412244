import { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ImageGallery = ({ images = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const sliderRef = useRef(null);

  const nextImage = () => {
    if (images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      sliderRef.current?.scrollBy({ left: 80, behavior: "smooth" });
    }
  };

  const prevImage = () => {
    if (images.length > 0) {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
      sliderRef.current?.scrollBy({ left: -80, behavior: "smooth" });
    }
  };

  return (
    <div className="image_section">
      {/* Main Image */}
      <div className="main_image">
        {images.length > 0 ? (
          <img
            src={images[currentImageIndex]}
            alt={`Product view ${currentImageIndex + 1}`}
            width={400}
            height={500}
            className="main_image_img"
          />
        ) : (
          <p>No images available</p>
        )}
      </div>

      {/* Thumbnail Section */}
      {images.length > 0 && (
        <div className="thumbnail_container">
          <div ref={sliderRef} className="thumbnail_slider">
            {images.map((src, index) => (
              <div
                key={index}
                className={`thumbnail ${index === currentImageIndex ? "active_thumbnail" : ""}`}
                onClick={() => setCurrentImageIndex(index)}
              >
                <img
                  src={src}
                  alt={`Thumbnail view ${index + 1}`}
                  width={80}
                  height={80}
                  className="thumbnail_img"
                />
              </div>
            ))}
          </div>

          {/* Navigation Buttons */}
          {images.length > 4 && (
            <>
              <button onClick={prevImage} className="slider_button left">
                <FontAwesomeIcon icon={faChevronLeft} className="icon" />
              </button>
              <button onClick={nextImage} className="slider_button right">
                <FontAwesomeIcon icon={faChevronRight} className="icon" />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
