

export const ProductDetailView = ({ productDesc }) => {
  return (
    <div className="tab_details">
    <div>
      {/* <strong>Shirt:</strong> */}
      <ul className='tab_list'>
        <p>{productDesc}</p>
      </ul>
    </div>
  </div>
);
}


export const ShippingDetailView = () => (
    <div className="tab_details">
             <div>
                <strong>Shipping and Handling:</strong>
                <ul className='tab_list'>
                    <li>
                        You will receive a verification code (OTP) for order verification. Orders are processed after verification.
                    </li>
                    <li>Tracking details will be shared via email after the order is shipped.</li>
                    <li>For Cash on Delivery, pay the rider before opening the package.</li>
                </ul>
             </div>

             <div>
                <strong>Local Orders</strong>
                <ul className='tab_list'>
                    <li>Karachi: 2-3 working days. Rest of Pakistan: 3-5 working days.</li>
                    <li>Sale periods may extend delivery times to 5-7 working days.</li>
                    <li>Delivery charges: PKR 99 for all nationwide orders.</li>
                </ul>
             </div>

             <div>
                <strong>International Orders</strong>
                <ul className='tab_list'>
                    <li>Karachi: 2-3 working days. Rest of Pakistan: 3-5 working days.</li>
                    <li>Sale periods may extend delivery times to 5-7 working days.</li>
                    <li>Delivery charges: PKR 99 for all nationwide orders.</li>
                </ul>
             </div>
          </div>
);