// data.js

export const stats = [
    { icon: "🛍️", label: "Products Sold", value: "1M+" },
    { icon: "👥", label: "Happy Customers", value: "500K+" },
    { icon: "⚡", label: "Years in Business", value: "10+" },
  ];
  
  export const teamMembers = [
    {
      name: "Jane Doe",
      role: "CEO",
      image: "/img1.jpeg?height=400&width=400",
      bio: "Jane has been leading our company since its inception, bringing over 15 years of e-commerce experience.",
    },
    {
      name: "John Smith",
      role: "CTO",
      image: "/img2.jpeg?height=400&width=400",
      bio: "John is the tech wizard behind our platform, ensuring a smooth shopping experience for all our customers.",
    },
    {
      name: "Emily Brown",
      role: "Head of Customer Service",
      image: "/img3.jpeg?height=400&width=400",
      bio: "Emily leads our customer service team, making sure every customer query is handled with care and efficiency.",
    },
    {
      name: "Michael Johnson",
      role: "Head of Marketing",
      image: "/img4.jpeg?height=400&width=400",
      bio: "Michael crafts our brand story and ensures it resonates with our audience across all channels.",
    },
    {
      name: "Sarah Lee",
      role: "Product Manager",
      image: "/img1.jpeg?height=400&width=400",
      bio: "Sarah oversees our product development, bringing innovative items to our catalog.",
    },
  ];
  
  export const values = [
    {
      icon: "❤️",
      label: "Quality",
      description: "We are committed to offering premium designs with the finest materials and craftsmanship.",
    },
    {
      icon: "✨",
      label: "Integrity",
      description: "We uphold ethical practices and transparency in everything we do.",
    },
    {
      icon: "🌍",
      label: "Customer Satisfaction",
      description: "Your happiness and confidence in our products are our top priorities.",
    },
  ];
  