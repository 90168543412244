// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.price_display {
    font-family: Arial, sans-serif;
  }
  
  .prices {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .actual_price {
    color: #103178;
  }
  
  .other_prices {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .price_level {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 10px 0px;
    border-radius: 5px;
  }
  
  .price_level .quantity {
    font-weight: bold;
    color: #12a05c;
  }
  
  .price_level .price {
    color: var(--dark);
    font-weight: 500;
    margin-left: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/product/productitempricelevel/itempricelevel.view.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;EAChC;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,2BAA2B;IAC3B,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;EACnB","sourcesContent":[".price_display {\r\n    font-family: Arial, sans-serif;\r\n  }\r\n  \r\n  .prices {\r\n    font-size: 20px;\r\n    font-weight: bold;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .actual_price {\r\n    color: #103178;\r\n  }\r\n  \r\n  .other_prices {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n  }\r\n  \r\n  .price_level {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    padding: 10px 15px 10px 0px;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .price_level .quantity {\r\n    font-weight: bold;\r\n    color: #12a05c;\r\n  }\r\n  \r\n  .price_level .price {\r\n    color: var(--dark);\r\n    font-weight: 500;\r\n    margin-left: 10px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
