import React from 'react'
import './Title.css'

const Title = ({title, desc}) => {
  return (
    <div className='title'>
      <h1>{title}</h1>
      <span></span>
      <p>{desc}</p>
    </div>
  )
}

export default Title
