import React from 'react'
import './Contact.css'

const Contact = () => {
  return (
    <>
        <section className="contact-address-area">
            <div className="container">
                <div className="sec-title-style1 text-center max-width">
                    <div className="title">Contact Us</div>
                    <div className="text"><div className="decor-left"><span></span></div><p>Quick Contact</p><div className="decor-right"><span></span></div></div>
                    <div className="bottom-text">
                        <p>
                          We’d love to hear from you! Whether you have a question about our designs, 
                          need assistance, or want to explore partnership opportunities, Aseel's Closet 
                          is here to help. Reach out to us, and let’s connect!
                        </p>
                    </div>
                    <div className="contact-address-box row">
                            <div className=" single-contact-address-box main-branch">
                                <div className="inner">
                                    <ul>
                                        <li>
                                            <div className="title">
                                                <h4>Address:</h4>
                                            </div>
                                            <div className="text">
                                                <p>Desire Fashion, Shop No. 17, Rabi Center,<br /> D Ground, Harian Wala Chowk,<br /> Faisalabad, Pakistan</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title">
                                                <h4>Phone:</h4>
                                            </div>
                                            <div className="text">
                                                <p>03009122240 <br /> 03127922240</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="title">
                                                <h4>Email:</h4>
                                            </div>
                                            <div className="text lowercase">
                                                <p>asmach26@gmail.com</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </section>  
 
        {/* <section className="contact-info-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="contact-form">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="sec-title-style1 float-left">
                                        <div className="title">Send Your Message</div>
                                        <div className="text"><div className="decor-left"><span></span></div><p>Contact Form</p></div>
                                    </div>
                                    <div className="text-box float-right">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                                    </div>
                                </div> 
                            </div>   
                            <div className="inner-box">
                                <form id="contact-form" name="contact_form" className="default-form" action="inc/sendmail.php" method="post">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-12">
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="input-box">   
                                                        <input type="text" name="form_name" value="" placeholder="Name" required="" />
                                                    </div> 
                                                    <div className="input-box"> 
                                                        <input type="text" name="form_phone" value="" placeholder="Phone" />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="input-box"> 
                                                        <input type="email" name="form_email" value="" placeholder="Email" required="" />
                                                    </div>
                                                    <div className="input-box"> 
                                                        <input type="text" name="form_website" value="" placeholder="Website" />
                                                    </div> 
                                                </div>  
                                            </div> 
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="input-box"> 
                                                        <input type="text" name="form_subject" value="" placeholder="Subject" />
                                                    </div>     
                                                </div> 
                                            </div>   
                                        </div>
                                        <div className="col-xl-6 col-lg-12">
                                            <div className="input-box">    
                                                <textarea name="form_message" placeholder="Your Message..." required=""></textarea>
                                            </div>
                                            <div className="button-box">
                                                <input id="form_botcheck" name="form_botcheck" className="form-control" type="hidden" value="" />
                                                <button type="submit" data-loading-text="Please wait...">Send Message<span className="flaticon-next"></span></button>    
                                            </div>         
                                        </div> 
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> */}
    </>
  )
}

export default Contact
