import React from 'react'
import './order.view.css'
import { OrderDetailView, OrderHistoryView } from './order.allviews'

const Order = () => {
  return (
    <div>
        <OrderHistoryView />
        <OrderDetailView />
    </div>
  )
}

export default Order