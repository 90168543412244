
import { faChevronDown, faChevronLeft, faChevronRight, faFemale, faList} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useRef  } from 'react';
import { Link, useNavigate } from 'react-router-dom';


export const renderDropdownView = (validCategoriesData) =>(
<div className='categories'>
  {Object.keys(validCategoriesData).map((parentCategory, index) => (
        <div className='category_list' key={index}>
        <span>{parentCategory}</span>
        <ul>
            {validCategoriesData[parentCategory].map((cat, idx) => (
            <li key={idx}>
                <a href="#category">{cat.productcategory_NAME}</a>
            </li>
            ))}
        </ul>
        </div>
    ))};
    </div>
)


export const renderFooterView = (validCategoriesData) =>(
<div className='categories'>
        <div className='category_list'>
        <ul>
        {validCategoriesData.map((parentCategory, index) => (
            <li key={index}>
                <a href="#category">{parentCategory.productcategory_NAME}</a>
            </li>
            ))}
        </ul>
        </div>
    </div>
)


export const renderSliderView = (validCategoriesData) => (
  <div className='categories'>
        <div className='catagories_wrapper'>
          {validCategoriesData.map((category, index) => (
            <div key={index} className='category'>
              <div className='category_content'>
                <Link to='/'>{category.productcategory_NAME}</Link>
                {/* <p>(6 Items)</p> */}
              </div>
              <div className='img_container'>
                <img src={category.productcategoryimage_URL} alt={`category ${index + 1}`} />
              </div>
            </div>
          ))}
        </div>
  </div>
);


export const RenderListView = (validCategoriesData) => {
  const categoriesArray = Array.isArray(validCategoriesData)
  ? validCategoriesData
  : [validCategoriesData];

  const navigate = useNavigate();
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleSublistVisibility = (categoryPath) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryPath]: !prev[categoryPath],
    }));
  };


  const handleCategoryClick = (cat) => {
    const formattedCategoryName = cat.productcategory_NAME?.replace(/\s+/g, '-');
    navigate(`/categories/${cat.productcategory_ID}/${formattedCategoryName}`);
  };


  const RenderSubCategories = ({ sublist = [], parentPath = '' }) => {
    return (
      <ul className="category_menu">
        {sublist.map((subcat, index) => {
          const subcatPath = `${parentPath}-${subcat.productcategory_ID}`;
          return (
            <li key={index}>
              <div
                className="subcategory_name_wrapper"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSublistVisibility(subcatPath); 
                }}
              >
                <p
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleCategoryClick(subcat); 
                  }}
                >
                  {subcat.productcategory_NAME}
                </p>
                {subcat.sublist && subcat.sublist.length > 0 && (
                  <FontAwesomeIcon
                    className={`left ${
                      expandedCategories[subcatPath] ? 'rotate' : ''
                    }`}
                    icon={faChevronDown}
                  />
                )}
              </div>
              {expandedCategories[subcatPath] &&
                subcat.sublist &&
                subcat.sublist.length > 0 && (
                  <RenderSubCategories
                    sublist={subcat.sublist}
                    parentPath={subcatPath}
                  />
                )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="departments">
      <div className="heading">
        <FontAwesomeIcon icon={faList} />
        <h4>SHOP BY DEPARTMENT</h4>
      </div>
      <div className="dep_wrapper">
        {categoriesArray.map((category) => (
          <div
            className={`category ${
              expandedCategories[category.productcategory_ID] ? 'show' : 'hide'
            }`}
            key={category.productcategory_ID}
          >
            <div
              className="category_name_wrapper"
              onClick={() =>
                toggleSublistVisibility(category.productcategory_ID)
              }
            >
              <div
                className="category_name"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCategoryClick(category);
                }}
              >
                <FontAwesomeIcon icon={faFemale} />
                <p>{category.productcategory_NAME}</p>
              </div>
              {category.sublist && category.sublist.length > 0 && (
                <FontAwesomeIcon
                  className={`left ${
                    expandedCategories[category.productcategory_ID]
                      ? 'rotate'
                      : ''
                  }`}
                  icon={faChevronDown}
                />
              )}
            </div>
            {expandedCategories[category.productcategory_ID] &&
              category.sublist &&
              category.sublist.length > 0 && (
                <RenderSubCategories
                  sublist={category.sublist}
                  parentPath={category.productcategory_ID.toString()}
                />
              )}
          </div>
        ))}
      </div>
    </div>
  );
};


export const RenderFilterView = (validCategoriesData) => {
  const [selectedCategories, setSelectedCategories] = useState({});

  const handleClick = (categoryId) => {
    setSelectedCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  // Recursive function to render nested categories
  const renderNestedCategories = (sublist) => {
    return (
      <ul>
        {sublist.map((subcat,index) => (
          <li key={index}>
            <div
              onClick={() => handleClick(subcat.productcategory_ID)}
              style={{
                cursor: 'pointer',
                color: selectedCategories[subcat.productcategory_ID] ? 'black' : 'var(--gray)',
                fontWeight: selectedCategories[subcat.productcategory_ID] ? '600' : '500',
              }}
            >
              {subcat.productcategory_NAME}
            </div>
            {/* Render subcategories if they exist and are selected */}
            {subcat.sublist && subcat.sublist.length > 0 && renderNestedCategories(subcat.sublist)}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className='cat_list'>
      {renderNestedCategories(validCategoriesData)}
    </div>
  );
};

  