export const setting = {
    AppsStorePath: "http://apps.kbfood.services/#/",
    LoginAppPath: "http://accounts.kbfood.services/#/",
    application_ID: "KBFSProductManagement",
  
    companyName: 'Compuwiz Technologies',
    companyShortName: 'CWizTech',
    logo: 'http://kbfood.services/design/images/logo.png',
    logo1white: 'http://kbfood.services/design/images/logo-short.png',
    loginBanner1: 'http://kbfood.services/design/images/intro-back.jpeg',
    icon: 'http://kbfood.services/design/images/favicon.ico',
  
    servicePath: "aseelscloset/",
    commonServicePath: "cwiztech/",
  
    isApplication: false,
    isHash: '/#',
    redirctPath: '',
    //redirctPath: '/products',
  };
  
  /*
  
    application_ID: "CWIZTECHProductManagementDev",
  
  -------------------CWIZTECH-------------------
    AppsStorePath: "http://apps.cwiztech.com/#/",
    LoginAppPath: "http://accounts.cwiztech.com/#/",
    application_ID: "CWIZTECHProductManagement",
  
    companyName: 'Compuwiz Technologies',
    companyShortName: 'CWizTech',
    logo: 'http://cwiztech.com/design/images/logo.png',
    logo1white: 'http://cwiztech.com/design/images/logo-short.png',
    loginBanner1: 'http://cwiztech.com/design/images/intro-back.jpeg',
    icon: 'http://cwiztech.com/design/images/favicon.ico',
  
    servicePath: "cwiztech/",
  
    -------------------KBFOOD-------------------
    AppsStorePath: "http://apps.kbfood.services/#/",
    LoginAppPath: "http://accounts.kbfood.services/#/",
    application_ID: "KBFSProductManagement",
  
    companyName: 'Compuwiz Technologies',
    companyShortName: 'CWizTech',
    logo: 'http://kbfood.services/design/images/logo.png',
    logo1white: 'http://kbfood.services/design/images/logo-short.png',
    loginBanner1: 'http://kbfood.services/design/images/intro-back.jpeg',
    icon: 'http://kbfood.services/design/images/favicon.ico',
  
    servicePath: "kbfs/",
  
       -------------------------- ASEELSCLOSET --------------------------
    AppsStorePath: "http://apps.aseelscloset.com/#/",
    LoginAppPath: "http://accounts.aseelscloset.com/#/",
    application_ID: "ASEELSCLOSETProductManagement",
  
    companyName: 'Aseels Closet',
    companyShortName: 'aseelscloset',
    logo: 'http://aseelscloset.com/assets/images/logo.jpg',
    logo1white: 'http://aseelscloset.com/assets/images/logo.jpg',
    loginBanner1: 'http://aseelscloset.com/assets/images/logo.jpg',
    icon: 'http://aseelscloset.com/favicon.ico',
  
    servicePath: "aseelscloset/",
  
  */
  
  