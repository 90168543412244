import React from 'react'

import './Breadcrumbs.css'

const BreadCrumbs = () => {
  return (
    <nav className="breadcrumbs">
        <ul className="breadcrumbs_list">
            <li className="breadcrumb_item"><a href="/">Home</a></li>
            <li className="breadcrumb_item">/</li>
            <li className="breadcrumb_item current">Product Catalog</li>
        </ul>
    </nav>
  )
}

export default BreadCrumbs