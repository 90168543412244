const dummyProducts = [
  {
    productitem_ID: "prod_1",
    productitem_NAME: "Classic Blue Kurta",
    productitem_DESC: "A traditional blue kurta made from premium fabric for a comfortable fit.",
    productitemimage_PATH: "https://via.placeholder.com/400x500.png?text=Blue+Kurta",
    productitem_DETAIL: {
      productitem_NAME: "Classic Blue Kurta",
      product_DETAIL: {
        product_NAME: "Maria B",
        unit_PRICE: "2999",
        product_CODE: "BK001",
      }
    },
    rating: 4.5,
    stock: 0,
    quantity_ONHAND: 0, 
  },
  {
    productitem_ID: "prod_2",
    productitem_NAME: "Elegant Red Dress",
    productitem_DESC: "A stunning red dress perfect for evening wear.",
    productitemimage_PATH: "https://via.placeholder.com/400x500.png?text=Red+Dress",
    productitem_DETAIL: {
      productitem_NAME: "Elegant Red Dress",
      product_DETAIL: {
        product_NAME: "Sana Safinas",
        unit_PRICE: "4999",
        product_CODE: "RD002",
      }
    },
    rating: 4.8,
    stock: 8,
    quantity_ONHAND: 8, 
  },
  {
    productitem_ID: "prod_3",
    productitem_NAME: "Casual White Shirt",
    productitem_DESC: "A breathable and stylish white shirt for daily wear.",
    productitemimage_PATH: "https://via.placeholder.com/400x500.png?text=White+Shirt",
    productitem_DETAIL: {
      productitem_NAME: "Casual White Shirt",
      product_DETAIL: {
        product_NAME: "Limelite",
        unit_PRICE: "1999",
        product_CODE: "WS003",
      }
    },
    rating: 4.2,
    stock: 20,
    quantity_ONHAND: 20, 
  },
  {
    productitem_ID: "prod_4",
    productitem_NAME: "Formal Black Suit",
    productitem_DESC: "An elegant black suit for formal occasions.",
    productitemimage_PATH: "https://via.placeholder.com/400x500.png?text=Black+Suit",
    productitem_DETAIL: {
      productitem_NAME: "Formal Black Suit",
      product_DETAIL: {
        product_NAME: "Khadi",
        unit_PRICE: "9999",
        product_CODE: "FBS004",
      }
    },
    rating: 4.9,
    stock: 5,
    quantity_ONHAND: 5, 
  },
];

export default dummyProducts;
