import React, { useState } from 'react'
import { useNavigate } from 'react-router';

import './ProductCard.css'

const ProductCard = ({number}) => {

    const navigate = useNavigate();

    const goToShop = (card) => {
        navigate('product/1');
    };


    const cards = [
        {
            id: 1,
            image: 'https://www.limelight.pk/cdn/shop/files/DSC02421_533x.jpg?v=1720611663',
            logo: 'images/hero.jpg',
            type : 'Deal',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '50% off orders during this summer clearance sale',
            expiration: '2024/12/31',
        },
        {
            id: 2,
            image: 'https://www.limelight.pk/cdn/shop/files/DSC09072_533x.jpg?v=1720960877',
            logo: 'images/hero.jpg',
            type : 'Code',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '30% off orders during this summer clearance sale',
            expiration: '2024/11/30',
        },
        {
            id: 3,
            image: 'https://www.limelight.pk/cdn/shop/files/DSCF0200_1a27ce01-2e0d-4474-b413-5e3fb4bb1d3c_533x.jpg?v=1717058937',
            logo: 'images/hero.jpg',
            type : 'Deal',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '25% off orders during this summer clearance sale',
            expiration: '2024/10/15',
        },
        {
            id: 4,
            image: 'https://www.limelight.pk/cdn/shop/files/DSC06987_4b1a934b-bbc6-4870-98c6-8c919c5433b9_533x.jpg?v=1720176726',
            logo: '/images/hero.jpg',
            type : 'Code',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '10% off orders during this summer clearance sale',
            expiration: '2024/09/01',
        },
        {
            id: 1,
            image: 'https://www.limelight.pk/cdn/shop/files/DSC07875_533x.jpg?v=1719560161',
            logo: 'images/hero.jpg',
            type : 'Deal',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '50% off orders during this summer clearance sale',
            expiration: '2024/12/31',
        },
        {
            id: 2,
            image: 'https://www.limelight.pk/cdn/shop/files/DSC08538_9412e59a-0828-42ad-98ad-a6c7ed494432_533x.jpg?v=1721283083',
            logo: 'images/hero.jpg',
            type : 'Code',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '30% off orders during this summer clearance sale',
            expiration: '2024/11/30',
        },
        {
            id: 3,
            image: 'https://www.limelight.pk/cdn/shop/files/DSC00096_a668a1dd-2135-4f59-97db-1e3c0939c63b_533x.jpg?v=1722332901',
            logo: 'images/hero.jpg',
            type : 'Deal',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '25% off orders during this summer clearance sale',
            expiration: '2024/10/15',
        },
        {
            id: 4,
            image: 'https://www.limelight.pk/cdn/shop/files/DSCF0586_f1953c03-f54a-4557-b21b-dc987b926c55_533x.jpg?v=1717058983',
            logo: '/images/hero.jpg',
            type : 'Code',
            heading: '3 Piece Lawn Suit-Paste Print (Unstitched)',
            subheading: '10% off orders during this summer clearance sale',
            expiration: '2024/09/01',
        },
    ];

    const cardsToDisplay = number ? cards.slice(0, number) : cards;
    
  return (
    <div className="voucher_cards">
            {cardsToDisplay.map((card,index) => (
                <div key={index} className="v_card" onClick={() => goToShop()}>
                    <img src={card.image} alt="Deal" className="card_image" />
                    <div className="card_content">
                        <div className="card_row">
                            {/* <span className="card_code">{card.type}</span> */}
                            <span className="card_source">Maria B</span>
                        </div>
                        <h3 className="card_heading">{card.heading}</h3>
                        <p className="card_expiration">RS. 3999</p>
                    </div>
                </div>
            ))}
             {/* <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="voucher_modal modal"
                overlayClassName="modal_overlay"
            >
                {currentCard && (
                    <div className="modal_content">
                        <div className="modal_header">
                            <div></div>
                            <button onClick={closeModal} className="modal_close">
                                <FontAwesomeIcon icon={faClose} />
                            </button>
                        </div>
                        <div className='divider'></div>
                        <div className="modal_body">
                            <div className='deal_info'>
                                <img src={currentCard.image} alt="Logo" className="modal_logo" />
                                <div>
                                    <h2>{currentCard.heading}</h2>
                                    <p>{currentCard.subheading}</p>
                                </div>
                            </div>
                            <div className="modal_code_section">
                                <span className="modal_code">#0954fg</span>
                                <button className="modal_copy_button">Copy code</button>
                            </div>
                            <a href="/" className="modal_shop_link">Go to shop</a>
                        </div>
                        <div className='divider'></div>
                        <div className="modal_footer">
                            <a href="/" className="modal_terms">Terms and conditions</a>
                            <div className="modal_share">Share this offer</div>
                        </div>
                    </div>
                )}
            </ReactModal> */}
        </div>
  )
}

export default ProductCard
