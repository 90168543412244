import React, { useEffect, useState } from 'react';
import './Home.css';
import { useDispatch } from 'react-redux';
import { setProducts } from '../../features/products/productsSlice';
import ProductCategory from '../../components/product/productcategory/productcategory.view';
import { get } from '../../components/product/productcategory/productcategory.service';
import { Link } from 'react-router-dom';
import MainSlider from '../../components/mainSlider/mainSlider';
import features from '../../data/Features.json'
import top_categories from '../../data/TopCategories.json'
import NewArrivals from '../../components/productSections/newarrivals/newarrivals';

const HomePage = () => {
  const [activeItem, setActiveItem] = useState('Popular');
  const [categoriesData, setCategoriesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get();
        setCategoriesData(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="home_page">
      <section className='home_hero_section'>
        <ProductCategory view={'list'} categoriesData={categoriesData}/>
        <div className='hero_wrapper'>
          <MainSlider />
        </div>
      </section>

      <section className='features'>
        <div className='features_wrapper'>
          {features.map((feature) => (
            <div key={feature.id} className='feature_container'>
              <div>
                <img src={feature.image} alt='image' />
              </div>
              <div className='feature_content'>
                <h4>{feature.title}</h4>
                <p>{feature.disp}</p>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </section>

      <section className='banner_section_1'>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_1.png' alt='banner 1' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_2.png' alt='banner 2' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_3.png' alt='banner 3' />
          </Link>
        </div>
      </section>

      <section className='top_categories'>
        <div className='heading'>
          <h1><span><b>Top Categories </b></span> <span>Of The Month</span></h1>
          <hr />
        </div>

        <ProductCategory view={'slider'} categoriesData={categoriesData}/>
      </section>

      <section className='banner_section_2'>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_4.png' alt='banner 4' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/hero_img_3.png' alt='banner 5' />
          </Link>
        </div>
      </section>

      <NewArrivals />

      {/* <TypeSection />
      <Collections /> */}
     
      {/* <section className='banner_section_3'>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_6.jpg' alt='banner 6' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_7.jpg' alt='banner 7' />
          </Link>
        </div>
        <div className='banner'>
          <Link to='/'>
            <img src='/assets/banner_8.jpg' alt='banner 8' />
          </Link>
        </div>
      </section> */}

    </div>
  );
};

export default HomePage;


