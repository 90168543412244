import React, { useEffect, useState } from "react";
import "./Categories.css";
import { getAll as getProducts } from "../../components/product/productitem/productitem.service";
import Product from "../../components/product/product/product.view";
import { getOne, get } from '../../components/product/productcategory/productcategory.service'; // Import both APIs
import ProductCategory from "../../components/product/productcategory/productcategory.view";
import { useParams } from "react-router";

const Categories = () => {
  const { id } = useParams(); // Get 'id' from URL
  const [categoriesData, setCategoriesData] = useState(null);
  const [group, setGroup] = useState(null); // Group name for the heading
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result;
        if (id) {
          // If 'id' exists, fetch data for the specific category
          result = await getOne(id);
          setGroup(result.productcategory_NAME); // Set the group name
        } else {
          // If 'id' is not present, fetch all categories
          result = await get();
          setGroup("All Categories"); // Default heading when no ID
        }
        setCategoriesData(result); // Set the fetched data
      } catch (err) {
        setError(err); // Handle any errors
      } finally {
        setLoading(false); // Stop the loading spinner
      }
    };

    fetchData();
  }, [id]);

  if (loading) return <div>Loading...</div>; // Show a loading indicator
  if (error) return <div>Error: {error.message}</div>; // Handle errors

  return (
    <div className="home_page">
      <h1 className="cat_group">{group}</h1> {/* Display group name */}
      <section className="home_hero_section">
        <ProductCategory view={"list"} categoriesData={categoriesData} />
        <div className="hero_wrapper"></div>
      </section>
    </div>
  );
};

export default Categories;
