import React from "react";
import '../privacy-policy/PrivacyPolicy.css'

const Terms = () => {
  return (
    <div className='main_faq'>
      {/* <div className='head_part'>
        <div className='arc'>
          <h1>
            Terms <br />
            of Use
          </h1>
        </div>
      </div> */}

      <div className='queryP'>
        <div className='main_cont'>
          <h1 className='heading1'>
            Aseel's Closet Terms and Conditions
          </h1>

          <p>
            Welcome to Aseel's Closet! By accessing or using our website, you agree 
            to comply with and be bound by the following terms and conditions. 
            Please read these carefully before engaging with our services. If you do 
            not agree with these terms, kindly refrain from using our website.
          </p>

          <h2>1. General Information</h2>
          <p>
            Aseel's Closet is an online platform offering premium original designs 
            and master replicas, ensuring guaranteed quality in color, embroidery, and fabric.
          </p>

          <p>
            These terms apply to all users of the site, including browsers, customers, 
            merchants, or contributors of content.
          </p>

          <h2>2. Orders and Payments</h2>

          <p>
            All orders are subject to availability. If a product becomes unavailable after 
            placing your order, we will notify you and provide a refund or alternative options.
          </p>

          <h2>3. Shipping and Delivery</h2>
          <p>
            Shipping times and costs vary depending on the destination and will be clearly stated at checkout.
          </p>
          <p>
            We strive to deliver orders promptly. However, delays caused by unforeseen circumstances or courier issues are beyond our control.
          </p>

          <h2>4. Product Descriptions</h2>
          <ol>
            <li>
              We make every effort to display our products as accurately as possible, 
              including colors and details. However, slight variations may occur due 
              to monitor settings or lighting.
            </li>
          </ol>

          <h2>5. Returns and Exchanges</h2>
          <ol>
            <li>
              If you’re unsatisfied with your purchase, you may return or exchange eligible items 
              within 7 days of receiving them, provided they are in their original condition.
            </li>
            <li>
              Customers are responsible for return shipping fees unless the return is due to a 
              defect or error on our part.
            </li>
          </ol>

          <h2>6. Use of the Website</h2>
          <p>
            By using our website, you agree not to engage in any unlawful activities or misuse 
            of the platform. This includes:
          </p>
          <ol>
            <li>
              Attempting to gain unauthorized access to our servers or databases.
            </li>
            <li>
              Transmitting any malicious code, viruses, or harmful content.
            </li>
            <li>
              Using the website for fraudulent activities.
            </li>
          </ol>

          <h2>7. Intellectual Property</h2>
          <p>
            All content on Aseel's Closet, including text, images, logos, and designs, 
            is our intellectual property or used with proper authorization. Unauthorized 
            use, reproduction, or distribution of any content is strictly prohibited.
          </p>

          <h2>8. Privacy Policy</h2>
          <p>
            Your personal information is protected under our Privacy Policy. By using 
            our website, you consent to our collection and use of your data as outlined 
            in the Privacy Policy.
          </p>

          <h2>9. Changes to Terms and Conditions</h2>
          <p>
            Aseel's Closet reserves the right to modify these Terms and Conditions at 
            any time. Updates will be posted on this page, and continued use of the 
            website constitutes acceptance of these changes.
          </p>

          <h2>10. Contact Us</h2>
          <p>For any questions or concerns regarding these Terms and Conditions, feel free to contact us at:</p>
          <ol>
            <li>
              <strong>Email:</strong> asmach26@gmail.com
            </li>
            <li>
              <strong>Phone Number:</strong>  03009122240
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Terms;
