import { useEffect, useState } from 'react';
import { FaCreditCard, FaPaypal, FaAmazon, FaTruck, FaDollarSign, FaHome, FaShoppingCart } from 'react-icons/fa';
import checkoutStyles from './checkout.module.css' // Import the CSS file
import Summary from '../../components/summary/summary.view';

 const Checkout = () => {
  const [paymentMethod, setPaymentMethod] = useState('cash-on-delivery');
  const [sameAsBilling, setSameAsBilling] = useState(false);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    setProducts(cart); 
    setLoading(false);
  }, []);

  const inputClasses = 'input_fie ld';

  return (
    <div className={checkoutStyles.checkout_page}>
  {/* <Hero title={'Shopping Cart'} /> */}

  <div className={checkoutStyles.main_grid}>
    <div className={checkoutStyles.form_section}>

      {/* Payment Method */}
      <div className={checkoutStyles.check_section}>
        <h2 className={checkoutStyles.section_title}>Payment Method</h2>
        <div className={checkoutStyles.payment_methods}>
          <div className={checkoutStyles.radio_group}>
            <input
              type="radio"
              name="paymentMethod"
              value="cash-on-delivery"
              checked={paymentMethod === 'cash-on-delivery'}
              onChange={() => setPaymentMethod('cash-on-delivery')}
              className={checkoutStyles.radio_input}
            />
            <label>
              <FaDollarSign className={checkoutStyles.icon} />
              Cash on Delivery
            </label>
          </div>
        </div>

        {/* Payment Details */}
        {paymentMethod === 'cash-on-delivery' && (
          <div className={checkoutStyles.payment_note}>
            <p>You will pay in cash when your order is delivered.</p>
          </div>
        )}
      </div>

      {/* Shipping Information */}
      <div className={checkoutStyles.check_section}>
        <h2 className={checkoutStyles.section_title}>Shipping Information</h2>
        <div className={checkoutStyles.form_group}>
          {/* <div className={checkoutStyles.checkbox_group}>
            <input
              type="checkbox"
              id="sameAsBilling"
              checked={sameAsBilling}
              onChange={(e) => setSameAsBilling(e.target.checked)}
              className={checkoutStyles.checkbox_input}
            />
            <label htmlFor="sameAsBilling">Same as billing address</label>
          </div> */}
          {!sameAsBilling && (
            <>
              <div className={checkoutStyles.row}>
                <div className={checkoutStyles.shipping_field}>
                  <input
                    type="text"
                    id="shippingFirstName"
                    className={checkoutStyles.inputClasses}
                    placeholder="First Name"
                  />
                </div>
                <div className={checkoutStyles.shipping_field}>
                  <input
                    type="text"
                    id="shippingLastName"
                    className={checkoutStyles.inputClasses}
                    placeholder="Last Name"
                  />
                </div>
              </div>
              <div className={checkoutStyles.shipping_field}>
                <input
                  type="text"
                  id="shippingAddress"
                  className={checkoutStyles.inputClasses}
                  placeholder="Address"
                />
              </div>

              <div className={checkoutStyles.row}>
                <div className={checkoutStyles.shipping_field}>
                  <input
                    type="text"
                    id="shippingCity"
                    className={checkoutStyles.inputClasses}
                    placeholder="City"
                  />
                </div>
                <div className={checkoutStyles.shipping_field}>
                  <input
                    type="text"
                    id="shippingZipCode"
                    className={checkoutStyles.inputClasses}
                    placeholder="Zip Code"
                  />
                </div>
              </div>

              <div className={checkoutStyles.row}>
                <div className={checkoutStyles.shipping_field}>
                  <input
                    type="text"
                    id="shippingEmail"
                    className={checkoutStyles.inputClasses}
                    placeholder="Email"
                  />
                </div>
                <div className={checkoutStyles.shipping_field}>
                  <input
                    type="text"
                    id="shippingPhone"
                    className={checkoutStyles.inputClasses}
                    placeholder="Phone Number"
                  />
                </div>
              </div>

              <div className={checkoutStyles.shipping_field}>
                <select id="shippingCountry" className={checkoutStyles.inputClasses}>
                  <option value="">Select a country</option>
                  <option value="us">United States</option>
                  <option value="ca">Canada</option>
                  <option value="uk">United Kingdom</option>
                </select>
              </div>
            </>
          )}
        </div>
      </div>
    </div>

    {/* Order Summary */}
    <Summary page={'Order'} products={products} />
  </div>
    </div>

  );
}

export default Checkout