import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Link } from 'react-router-dom';
import './summary.view.css'
import { SummaryView } from './summary.allviews';

const Summary = ({page, products}) => {

  return (
    <SummaryView page={page} products={products} />
  )
}

export default Summary