import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import VCard from '../../components/productCard/ProductCard';
import './Product.css';
import Counter from '../../components/counter/Counter';
import { getOne } from '../../components/product/productitem/productitem.service';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faChevronLeft, faChevronRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Tabs from '../../components/tabs/Tabs';
import {parseNestedJSON} from '../../utils';
import { ProductDetailView, ShippingDetailView } from '../../components/product/productdetails/productdetails.allviews';
import { PriceDisplay } from '../../components/product/productitempricelevel/itempricelevel.view';
import { Link } from 'react-router-dom';
import ImageGallery from '../../components/product/productItemimage/productitemimages.view';

const Product = () => {
  const [selectedColor, setSelectedColor] = useState('Red');
  const [productData, setProductData] = useState(null);
  const [stock, setStock] = useState(null);
  const [desc, setDesc] = useState(null);
  const [images, setImages] = useState(null);
  const [price, setPrice] = useState(null);
  const [brand, setBrand] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const sliderRef = useRef(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get('productId');
  

  useEffect(() => {
    const fetchData = async (id) => {
      try {
        const result = await getOne(id);
        const parsedData = parseNestedJSON(result); 
        console.log('parsedData',parsedData) 
        const inventory = parsedData.productiteminventory[0].quantity_AVAILABLE;
        const imageUrls = parsedData.productitemimage.map(image => image.productitemimage_PATH);

        setProductData(parsedData);
        setStock(inventory)
        setDesc(parsedData.productitem_DESC)
        setImages(imageUrls)
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData(productId);
  }, [productId]);
  

  const productTabsConfig = [
    {
      key: 'details',
      label: 'Product Details',
      content: <ProductDetailView productDesc={desc}/>,
    },
    {
      key: 'shipping',
      label: ' Shipping and Handling',
      content: <ShippingDetailView />,
    },
  ];

  const handleAddToCart = () => {
    const cartItem = {
      product: productData,
      quantity: quantity,
    };
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    const existingProductIndex = cart.findIndex(item => item.product.productitem_ID === productData.productitem_ID);
  
    if (existingProductIndex !== -1) {
      cart[existingProductIndex].quantity += quantity;
    } else {
      cart.push(cartItem);
    }
  
    localStorage.setItem('cart', JSON.stringify(cart));
    toast.success('Product added to cart');
  };
  

  return (
    <div className="single_product_page">
      <div className="product_card">
        <div className='product_card_left'>
          <div className="image_section">
          {productData ? (
            <ImageGallery images={images} />
          ) : (
            <p>Loading images...</p>
          )}
          </div>

          {
            productData && 
            <>
              <div className="product_details">
                <h2 className="product_title">{productData.productitem_NAME}</h2>
                <p className="product_code">{productData?.product_DETAIL.product_NAME}</p>
                <p className='product_code'>Product Code: {productData.product_DETAIL.product_CODE}</p>
                <div className='pdetail_price'>
                  <PriceDisplay pricelevel={productData.productitempricelevel} />
                  <p className='stock_status'>
                    <FontAwesomeIcon  icon={stock > 0 ? faCheckCircle : faTimesCircle}  /> {stock > 0 ? 'In Stock' : 'Out of Stock'}
                  </p>  
                </div>
                <div className='stock_detail'>
                  {
                    stock < 11 && stock > 0 &&
                    <>
                      <p>Hurry! only <span className='stock_left'>{stock}</span> left in stock.</p>
                    </>
                  }
                  <p></p>
                </div>
                <div className='product_desc'>
                  <p></p>
                </div>
                <div className="color_and_quantity">
                  <div className="quantity_selector">
                    <Counter quantity={quantity} setQuantity={setQuantity} />
                  </div>
                  <Link
                    href={stock ? "/cart" : "#"}
                    className={`get_deal_btn ${!stock ? 'disabled' : ''}`}
                    onClick={(e) => {
                      if (!stock) {
                        e.preventDefault();
                      } else {
                        handleAddToCart();
                      }
                    }}
                  >
                    Add to Cart
                  </Link>
                </div>
                <Tabs 
                  tabsConfig={productTabsConfig} 
                  defaultActiveTab="details" 
                />
              </div>
            </>
          }
        </div>
      </div>
     {/* <div className='about_deal_card'> 
        <div className='deal-wrapper'>
          <h3 className='section-title'>You May Also Like</h3>
          <div className='stores'>
            <VCard />
          </div>
        </div>
     </div> */}
    </div>
  );
};

export default Product;
