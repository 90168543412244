import React from 'react'
import './Hero.css'
import { useLocation } from 'react-router';
import BreadCrumbs from '../breadcrumbs/Breadcrumbs';

const Hero = ({title}) => {
  
  const location = useLocation();
  return (
    <>
    {location.pathname === '/' ? (
      <section className="hero_section">
        <img src="https://mymedi-react.nouhtml5.com/static/img/Mask-Group.jpg" alt="Hero Background" className="hero_background" />
        <div className="hero_content">
          <h1>{title}</h1>
          {/* <p>Your hero subtitle or description goes here. Make it engaging and informative.</p> */}
          {/* <a href="#cta" className="cta_button">Call to Action</a> */}
        </div>
      </section>
    ) : (
      <section className="hero">
        <div className="hero_bg">
          <h1 className="hero_heading">Product Catalog</h1>
          <BreadCrumbs />
        </div>
      </section>
    )}
  </>
  )
}

export default Hero