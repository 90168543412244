import './itempricelevel.view.css';

export const PriceDisplay = ({ pricelevel }) => {
  // Find primary price
  const primaryPrice = pricelevel.find(
    (level) => level.productitem_QUANTITY === 1
  );

  return (
    <div className="price_display">
      {/* Display primary price */}
      <p className="prices">
        {primaryPrice ? (
          <span className="actual_price">
            PKR {primaryPrice.productitem_UNITPRICE}
          </span>
        ) : (
          <span className="actual_price">Price not available</span>
        )}
      </p>

      {/* Display other price levels */}
      <div className="other_prices">
        {pricelevel
          .filter((level) => level.productitem_QUANTITY !== 1)
          .map((level, index) => (
            <div key={index} className="price_level">
              <span className="quantity">For {level.productitem_QUANTITY} items:</span>
              <span className="price">PKR {level.productitem_UNITPRICE}</span>
            </div>
          ))}
      </div>
    </div>
  );
};
