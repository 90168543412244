import React, { useState } from 'react'

import './Account.css'
import Tabs from '../../components/tabs/Tabs'
import { OrderDetailView, OrderHistoryView } from '../../components/order/order.allviews';
import { Link } from 'react-router-dom';
import Tab from '../../components/tabs/Tabs';

const Account = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleSelectOrder = (order) => {
    setSelectedOrder(order);
  };

  const handleBackToList = () => {
    setSelectedOrder(null);
  };
  const tabsConfig = [
    // {
    //   key: 'account',
    //   label: 'Account Settings',
    //   content: <h1>Settings</h1>,
    // },
    {
      key: 'history',
      label: 'Order History',
      content: selectedOrder ? (
        <OrderDetailView order={selectedOrder} onBackToList={handleBackToList} />
      ) : (
        <OrderHistoryView onSelectOrder={handleSelectOrder} />
      ),
    },
  ];

  return (
    <div className="account_container">
      <Tab tabsConfig={tabsConfig} defaultActiveTab="history" />
      {/* <Tab  /> */}
    </div>
);
};

export default Account



